.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-width: 1224px) {
  div .main-content .inner-box {
    /*width: 960px;*/
    width:  100%;
    box-sizing: border-box;
    padding: 20px;
  }

  .round-container {
    border: 1px solid #BCC1CC;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }

  div .section {
    width:  100%;
    border-right: 1px solid #BCC1CC;
    display: flex;
    padding: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #0E0E0F;
    /*height: 80px;*/
    flex-direction: column;
  }
  
  div .section h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #0E0E0F;
    margin:  0;
    padding:  0;
    margin-top:  5px;
  }

  .mt-4 {
    margin-top: 12px;
  }

  div .containers {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
  }

  div .containers .round-container {
    margin-right: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px;
    flex-direction: column;
    height: 240px;
    width: auto;
  }

  .dash {
    display: flex;
  }
  div .dash .menu {
    display: block;
    margin-right: 10px;
  }


  .close {
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  .menu-dash {
    position: absolute;
    bottom: 49px;
    margin-left: 16px;
  }

  div .table-container {
    overflow: auto;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  thead {
    overflow: auto;
    white-space: nowrap;
  }

  div .setting-row {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  div .setting-form {
    width: 100%;
  }
  div .setting-group {
    flex-direction: column;
    /* width:  100%; */
  }

  div .main-content {
    width: 100%
  }

  .setting-form-button {
    width: 100%
  }

  .order-form-button {
    width: 100%
  }

  .order-map-container {
    margin-top: 60px;
  }

  .top-margin {
    margin-top: 30px;
  }

  div .pickup-field .pickup-input {
    width: 93%;
    min-width: 0px;
  }
}

.order-table {
  /* width:  100%; */
  border-radius: 6px;
  border-spacing: 0;
  border: 1px solid #BCC1CC;
  overflow: hidden;
}

.no-width {
  width: auto;
}
.order-card {
  border-bottom: 1px solid #BCC1CC;
  text-align: left;
  padding:  10px 10px;
  font-size: 12px;
}

.order-item {
  display: flex;
  align-items: center;
}
.order-property {
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 600;
  width: 40%;
}

.order-value {
  width: 60%;
}


.warning {
  background: pink;
  padding: 10px;
}

.warning b {
  color: maroon;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.scroll::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  width: 4px;
  background: #acb0c5;
  border-radius: 200px;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.activePay {
  border: 2px solid gray;
}

.inactivePay {
  border: 2px solid #00c795;
}

.desktop-only {
  @media (min-width: 500px) {
    display: none;
  }
}
.light-mode {
  background-color: white;
  color: #0e0e0f;
  transition: background-color 0.3s ease;
}
.dark-mode {
  background-color: #0e0e0f;
  color: white;
}
.dark-header-mode {
  background-color: #202124;
  color: white;
}
.light-header-mode {
  background-color: white;
  color: #0e0e0f;
  transition: background-color 0.3s ease;

background: #FFFFFF;
border-bottom: 1px solid #EAECF0;
box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.02);
backdrop-filter: blur(8px);

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* iframe's parent node */
div#frame {
  position: fixed;
  width: 100%;
  height: 100%;
}

/* iframe itself */
div#frame > iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}



/* v2 redesign */
.login-container {
  margin-top:  70px;
}
.login-header {
  width: 327px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #0E0E0F;
}
.login-header-sub {
  width: 268px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #667085;
  width: 100%;
  margin-top:  8px;
}
.login-label {
  font-size: 14px;
  line-height: 20px;
  color: #0E0E0F;
  width: 100%;
}

.error-label {
  font-size: 13px;
  color: #e21f33;
  width: 100%;
  font-weight: bold;
}
.show-icon {
  background: url('./assets/icons/svgs/ic-pass-hidden.svg') no-repeat;
  background-size: 24px 24px;
  width:  24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;
}
.show-icon.active {
  background: url('./assets/icons/svgs/ic-pass-visible.svg') no-repeat;
}
.show-icon:hover {
  opacity: .7;
}
input:focus {
  background:  #fff;
  border: 2px solid #0E0E0F;
  border-radius: 8px;
}

/*ic-user-line.png
ic-add.png
ic-back.png
ic-checkbox.png
ic-chevron-down.png
ic-date.png
ic-edit-1.png
ic-edit.png
ic-home.png
ic-logout.png
ic-next.png
ic-pass-hidden.png
ic-pass-visible.png
ic-remove.png
ic-schedule.png
ic-select-misc.png
ic-selected.png
ic-support.png
ic-upload.png*/

.side-menu {
  list-style: none;
  padding: 0;
  margin:  0;
  margin-left:  20px;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  margin-top: 20px;
}

.side-menu li {
  padding-left: 50px;
  display: flex;
  padding-bottom: 12px;
  padding-top:  12px;
  padding-right: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0E0E0F;
}

.side-menu li:hover {
  opacity: .6;
  cursor: pointer !important;
}

.side-menu .home {
  background: url('./assets/icons/svgs/ic-home.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
}

.side-menu .wallet {
  background: url('./assets/icons/svgs/wallet2.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
}

.side-menu .orders {
  background: url('./assets/icons/svgs/ic-orders.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
}

.side-menu .settings {
  background: url('./assets/icons/svgs/ic-settings.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
}

.side-menu .support {
  background: url('./assets/icons/svgs/ic-support.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
}

.side-menu .logout {
  background: url('./assets/icons/svgs/ic-logout.svg') no-repeat;
  background-size: 24px;
  background-position: 16px center;
  filter: invert(52%) sepia(36%) saturate(7455%) hue-rotate(326deg) brightness(99%) contrast(98%);
}

.side-menu .active {
  background-color: #F8F9FB;
  border-radius: 10px;
}


.main-content {
  display: flex;
  flex:  1;
}

.main-content .inner-box {
  /*width: 960px;*/
  width:  80%;
  box-sizing: border-box;
  padding: 20px;
}

h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #0E0E0F;
}

.round-container {
  border: 1px solid #BCC1CC;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.section {
  width:  100%;
  border-right: 1px solid #BCC1CC;
  display: flex;
  padding: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0E0E0F;
  /*height: 80px;*/
  flex-direction: column;
}

.section h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0E0E0F;
  margin:  0;
  padding:  0;
  margin-top:  5px;
}

.round-container h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
  color: #0E0E0F;
  margin:  0;
  padding:  0;
  margin-top:  5px;
}

.round-container p {
  padding:  0;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.section:last-child {
  border-right: 0px solid #BCC1CC;
}


.containers {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}

.containers .round-container {
  margin-right: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  flex-direction: column;
  height: 240px;
}

.round-container .expand {
  flex-grow: 1;
}

.containers .round-container:last-child {
  margin-right: 0;
}

.upload-box {
  background: #FCFCFF;
  border: 2px dashed #4545E6;
  border-radius: 8px;
  width: 99%;
  margin-bottom: 16px;
  margin-top:  16px;
  text-align: center;
  font-size: 14px;
}

.upload-box .upload-icon {
  background: url('./assets/icons/svgs/ic-upload.svg') no-repeat;
  background-size: 48px 48px;
  background-position: center center;
  width: 100%;
  height: 50px;
  filter: invert(25%) sepia(72%) saturate(2597%) hue-rotate(233deg) brightness(89%) contrast(102%);
  margin-top: 15px;
  margin-bottom: 10px;
}

.upload-box span {
  color: #4545E6;
  font-weight: 700;
}

.general-button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  background: #00C795;
  color: #fff;
}


h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  padding-bottom: 10px;
}

.setting-group {
  display: flex;
  width:  100%;
}
.setting-form {
  flex-grow: 1;
  border-top: 1px solid #BCC1CC;
  margin-right: 40px;
}
.setting-form.order {
  border: 0;
}
h3.order {
  border-bottom: 1px solid #BCC1CC;
}
.setting-map {
  width:  340px;
  height: 350px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #BCC1CC;
}


.setting-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.setting-element {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}


.address-container {
  position: relative;
}
.address-box {
  padding: 0;
  margin: 0;
  list-style: none;
  /* position: absolute; */
  width: 100%;
  background: #fff;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #BCC1CC;
  border-radius: 8px;
}

.address-box li {
  padding: 12px;
  border-bottom: 1px solid #BCC1CC;
  cursor: pointer;
}

.address-box li:hover {
  opacity: .8;
  background: #eaeaea;
}
.address-box li:last-child {
  border-bottom: 0;
}




/* orders */
.top-box {
  width:  100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.top-box.left {
  flex-direction: row;
  padding: 0;
}

.top-box .back {
  background: transparent url('./assets/icons/svgs/ic-back.svg') no-repeat;
  background-size: 24px 24px;
  background-position: 5px center;
  padding-left: 40px;
  font-size: 14px;
  cursor: pointer;
}


.top-box button {
  align-items: center;
  padding: 10px 16px 10px 8px;
  gap: 4px;
  width: 126px;
  height: 44px;
  background-color: #F8F9FB;
  border: 1px solid #BCC1CC;
  border-radius: 8px;
  cursor: pointer;
  background: #F8F9FB url('./assets/icons/svgs/ic-add.svg') no-repeat;
  background-size: 24px 24px;
  background-position: 5px center;
  padding-left: 25px;
  font-weight: bold;
}

.top-box .back:hover,
.top-box button:hover {
  opacity: .7;
}


.tab-table {
  display: flex;
  flex-direction: column;
}

.tab-table .tab-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #BCC1CC;
  width: 100%;
}

.tab-table .tab-menu li {
  padding: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
  cursor: pointer;
  transition: all 1s ease-out;
}

.tab-table .tab-menu li:hover {
  color: #0E0E0F;
  border-bottom: 1px solid #0E0E0F;
}

.tab-table .tab-menu li.active {
  color: #0E0E0F;
  border-bottom: 1px solid #0E0E0F;
}

.tab-menu li:first-child {
  padding-left: 0;
}

.table-container {
  display: flex;
  width:  100%;
  margin-top:  20px;
}

.main-table {
  /*display: flex;*/
  width:  100%;
  border-radius: 6px;
  border-spacing: 0;
  border: 1px solid #BCC1CC;
  overflow: hidden;
}

.main-table th {
  border-collapse: collapse;
  background: #F8F9FB;
  padding:  10px 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  border-bottom: 1px solid #BCC1CC;
  text-align: left;
}

.main-table tr:hover,
.main-table tr.selected {
  background: #F8F9FB !important;
}

.main-table td {
  border-bottom: 1px solid #BCC1CC;
  text-align: left;
  padding:  10px 10px;
  font-size: 12px;
}

.main-table tr:last-child td {
  border: 0;
}

.table-options {
  display: flex;
  width: 100%;
  height: 50px;
  flex-direction: row-reverse;
  padding: 10px 0;
  box-sizing: border-box;
}

button.schedule,
button.cancel {
  border: 1px solid #FA4B5C;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
/*  background: url('./assets/icons/svgs/ic-remove.svg') no-repeat !important;
  background-size: 18px 18px !important;
  background-position: 2px center !important;*/
  padding-left:  32px;
  margin-left: 14px;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
  position: relative;
}

button.schedule span,
button.cancel span {
}

button.schedule .icon {
  position: absolute;
  top: 3px;
  left: 2px;
  display: inline-block !important;
  width: 24px !important;;
  height: 24px !important;;
  background-color: #000 !important;;
  -webkit-mask-image: url('./assets/icons/svgs/ic-schedule.svg') !important;;
  mask-image: url('./assets/icons/svgs/ic-schedule.svg') !important;;
  mask-position: 2px center !important;
  mask-size: 18px;
  mask-repeat: no-repeat;
}

button.cancel .icon {
  position: absolute;
  top: 3px;
  left: 2px;
  display: inline-block !important;
  width: 24px !important;;
  height: 24px !important;;
  background-color: #000 !important;;
  -webkit-mask-image: url('./assets/icons/svgs/ic-remove.svg') !important;;
  mask-image: url('./assets/icons/svgs/ic-remove.svg') !important;;
  mask-position: 2px center !important;
  mask-size: 18px;
  mask-repeat: no-repeat;
}

button.schedule {
  border: 1px solid #00C795;
  padding-left:  25px;
}

button.schedule:hover,
button.cancel:hover {
  border: 1px solid #000;
  background: #000 !important;
  color:  #fff;
}

button.cancel:hover .icon,
button.schedule:hover .icon {
  background-color: #fff !important;;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prev-button,
.next-button,
.est-button {
  height: 44px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  color: #000;
  width: 49%;
  background: #F8F9FB;
  border: 1px solid #BCC1CC;
  border-radius: 8px;
}

.est-button {
  background: #00C795;
  color: #fff;
  border: 0 !important;
}

.est-button.mod {
  width:  35%;
  margin-top:  20px;
}


.pickup-field label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 20px;
}

.pickup-field .pickup-input {
  font-family: 'Inter';
  font-style: normal;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 10px;
  min-width: 420px;
  border-radius: 8px;
  background: #F8F9FB;
  border: 1px solid #BCC1CC;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.dash .menu {
  display: none;
}
